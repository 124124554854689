import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getTranslate } from "react-localize-redux";
import axios from "axios";
import {
  Header,
  Button,
  Icon,
  Table,
  Segment,
  Message,
  Modal,
  Divider,
  Dimmer,
  Loader,
  Checkbox,
  Grid,
  Dropdown,
} from "semantic-ui-react";
import withRouter from "react-router/withRouter";
import {
  orderAdminExportOrders,
  orderAdminSetDisplayNonExportOrders,
  orderAdminOrdersSetActiveTab,
  orderAdminSetActiveCountries,
} from "../actions/user-action";
import config from "../config/config";

class OrderAdminOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingRefreshOrderList: false,
      isLoadingExportOrders: false,
      isLoadingExportOrdersFileAgain: false,
      acceptPendingOrdersConfirmOpen: false,
      pageIsLoading: false,
      showLoadingScreen: false,
      newOrdersArray: [],
      manuallyHandledOrdersArray: [],
      alreadyExportedOrdersArray: [],
      selectedTabIndex: 1,
      shouldDisableRefreshButton: false,
      countrySelectOptions: [],
      selectedCountryOptions: [],
    };
  }

  _refreshOrderList = () => {
    if (this.state.selectedTabIndex === 2) {
      this.setState({
        manuallyHandledOrdersArray: [],
      });
      this._getManuallyExportedOrders();
    } else if (this.state.selectedTabIndex === 3) {
      this.setState({
        alreadyExportedOrdersArray: [],
      });
      this._getAlreadyExportedOrders();
    } else {
      this.setState({
        newOrdersArray: [],
      });
      this._getNewOrders();
    }
  };

  handleRowClick = (orderItem) => {
    this.props.history.push("/order-admin/order/" + orderItem.ID);
  };

  _exportOrdersClick = () => {
    this.setState({ isLoadingExportOrders: true });
    this.props
      .orderAdminExportOrders(
        this.props.user,
        "export-new-orders",
        this.props.user.orderAdminSelectedLanguages
      )
      .then(() => {
        this.setState({
          newOrdersArray: [],
          manuallyHandledOrdersArray: [],
          alreadyExportedOrdersArray: [],
          isLoadingExportOrders: false,
        });
        this._getNewOrders();
        this._getManuallyExportedOrders();
        this._getAlreadyExportedOrders();
      });
  };

  _exportOrderFileAgain = () => {
    this.setState({ isLoadingExportOrdersFileAgain: true });
    this.props
      .orderAdminExportOrders(
        this.props.user,
        "export-pending-orders",
        this.props.user.orderAdminSelectedLanguages
      )
      .then(() => {
        this.setState({ isLoadingExportOrdersFileAgain: false });
      });
  };

  _acceptPendingOrder = () => {
    this.setState({
      acceptPendingOrdersConfirmOpen: true,
    });
  };

  closeModalWindow = (doAcceptPendingOrder) => {
    if (doAcceptPendingOrder) {
      this.setState({
        acceptPendingOrdersConfirmOpen: false,
        pageIsLoading: true,
      });
      this.props
        .orderAdminExportOrders(
          this.props.user,
          "accept-pending-orders",
          this.props.user.orderAdminSelectedLanguages
        )
        .then(() => {
          this.setState({
            newOrdersArray: [],
            manuallyHandledOrdersArray: [],
            alreadyExportedOrdersArray: [],
            pageIsLoading: false,
          });
          this._getNewOrders();
          this._getManuallyExportedOrders();
          this._getAlreadyExportedOrders();
        });
    } else {
      this.setState({
        acceptPendingOrdersConfirmOpen: false,
      });
    }
  };

  _orderDisplayTypeButtonPressed = (setToTabIndex) => {
    this.setState({
      newOrdersArray: [],
      manuallyHandledOrdersArray: [],
      alreadyExportedOrdersArray: [],
      selectedTabIndex: setToTabIndex,
    });
    if (setToTabIndex === 1) {
      this._getNewOrders();
    } else if (setToTabIndex === 2) {
      this._getManuallyExportedOrders();
    } else if (setToTabIndex === 3) {
      this._getAlreadyExportedOrders();
    }
    this.props.orderAdminOrdersSetActiveTab(setToTabIndex);
  };

  _getCountryOptions = async () => {
    const urlPath = config.API_ENDPOINTS.ORDER_ADMIN_COUNTRY_OPTIONS;
    Promise.all([await axios.get(urlPath)])
      .then((resp) => {
        const dataResponse = resp[0].data;
        let countryArray = [];
        for (let responseValue in dataResponse) {
          let countryName = dataResponse[responseValue];
          countryArray.push({
            key: countryName,
            text: countryName,
            value: countryName,
          });
        }
        this.setState({ countrySelectOptions: countryArray });
      })
      .catch((error) => {
        console.log("error allWccd", error);
      });
  };

  _loadOrders = async (category) => {
    if (this.props.user.orderAdminSelectedLanguages.length > 0) {
      let selectedCountriesString = this.props.user.orderAdminSelectedLanguages
        .join()
        .toLowerCase();

      const urlPath =
        config.API_ENDPOINTS.ORDER_ADMIN_GET_ORDERS +
        "?gId=" +
        this.props.user.gId +
        "&status=" +
        category +
        "&country=" +
        selectedCountriesString;
      Promise.all([await axios.get(urlPath)])
        .then((resp) => {
          const ordersResponse = resp[0].data;
          if (
            category === "new" ||
            category === "await_export" ||
            category === "pending" ||
            category === "in_progress"
          ) {
            this.setState({
              newOrdersArray: this.state.newOrdersArray.concat(ordersResponse),
            });
          } else if (category === "exported_manually") {
            this.setState({
              manuallyHandledOrdersArray: this.state.manuallyHandledOrdersArray.concat(
                ordersResponse
              ),
            });
          } else if (category === "exported") {
            this.setState({
              alreadyExportedOrdersArray: this.state.alreadyExportedOrdersArray.concat(
                ordersResponse
              ),
            });
          }
        })
        .catch((error) => {
          console.log("error allWccd", error);
        });
    }
  };

  _getNewOrders = async () => {
    this.setState({
      showLoadingScreen: true,
      shouldDisableRefreshButton: true,
    });
    await this._loadOrders("new");
    await this._loadOrders("await_export");
    await this._loadOrders("pending");
    await this._loadOrders("in_progress");
    this.setState({
      showLoadingScreen: false,
      shouldDisableRefreshButton: false,
    });
  };

  _getManuallyExportedOrders = async () => {
    this.setState({
      showLoadingScreen: true,
      shouldDisableRefreshButton: true,
    });
    await this._loadOrders("exported_manually");
    this.setState({
      showLoadingScreen: false,
      shouldDisableRefreshButton: false,
    });
  };

  _getAlreadyExportedOrders = async () => {
    this.setState({
      showLoadingScreen: true,
      shouldDisableRefreshButton: true,
    });
    await this._loadOrders("exported");
    this.setState({
      showLoadingScreen: false,
      shouldDisableRefreshButton: false,
    });
  };

  handleCountriesSelectChange = (e, { value }) => {
    this.setState({
      selectedCountryOptions: value,
    });
  };

  useNewFiltrationButtonPressed = () => {
    if (this.state.selectedCountryOptions.length > 0) {
      this.props.orderAdminSetActiveCountries(
        this.state.selectedCountryOptions
      );
      this._refreshOrderList();
    }
  };

  componentDidMount() {
    if (this.state.newOrdersArray.length < 1) {
      this._getCountryOptions();
      this._getNewOrders();
      this._orderDisplayTypeButtonPressed(0);
    }
  }

  render() {
    let orderItems = this.props.user.orderAdminOrders;
    let shouldDisableExportButton = false;
    let isShowingManuallyExportedOrders = false;
    let isShowingAlreadyExportedOrders = false;
    let pageTitle = this.props.translation("orderadmin_new_orders");

    if (this.props.user.orderAdminActiveOrderTabIndex === 1) {
      isShowingManuallyExportedOrders = false;
    } else if (this.props.user.orderAdminActiveOrderTabIndex === 2) {
      isShowingManuallyExportedOrders = true;
      pageTitle = this.props.translation("orderadmin_manually_exported_orders");
    } else if (this.props.user.orderAdminActiveOrderTabIndex === 3) {
      isShowingAlreadyExportedOrders = true;
      isShowingManuallyExportedOrders = false;
      pageTitle = this.props.translation("orderadmin_exported_orders");
    }

    if (!isShowingManuallyExportedOrders && !isShowingAlreadyExportedOrders) {
      orderItems = this.state.newOrdersArray;
    } else if (isShowingManuallyExportedOrders) {
      orderItems = this.state.manuallyHandledOrdersArray;
    } else if (isShowingAlreadyExportedOrders) {
      orderItems = this.state.alreadyExportedOrdersArray;
    }

    orderItems
      .sort(function(a, b) {
        return b.ID - a.ID;
      })
      .sort(function(a, b) {
        return b.export_to_pyramid - a.export_to_pyramid;
      });

    orderItems.forEach((orderItem) => {
      if (orderItem.order_status === "pending") {
        shouldDisableExportButton = true;
      }
    });

    let selectedCountryDropdownOptions = [];
    if (this.state.selectedCountryOptions.length > 0) {
      selectedCountryDropdownOptions = this.state.selectedCountryOptions;
    } else {
      selectedCountryDropdownOptions = this.props.user
        .orderAdminSelectedLanguages;
    }

    return (
      <div className="content">
        <div className="flex-1">
          {shouldDisableExportButton && (
            <Fragment>
              <Modal
                open={this.state.acceptPendingOrdersConfirmOpen}
                basic
                size="small"
              >
                <Header
                  icon="warning circle"
                  content="Godkänn export av ordrar"
                />
                <Modal.Content>
                  <p>Är du säker på att du vill godkänna exporten?</p>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    basic
                    color="red"
                    inverted
                    onClick={() => this.closeModalWindow(false)}
                  >
                    <Icon name="remove" /> Nej
                  </Button>
                  <Button
                    color="green"
                    inverted
                    onClick={() => this.closeModalWindow(true)}
                  >
                    <Icon name="checkmark" /> Ja
                  </Button>
                </Modal.Actions>
              </Modal>

              {!isShowingManuallyExportedOrders &&
                !isShowingAlreadyExportedOrders && (
                  <Fragment>
                    <Message>
                      <Message.Header>
                        {this.props.translation(
                          "orderadmin_exports_that_are_awaiting_acceptance"
                        )}
                        :
                      </Message.Header>
                      <Segment color="red">
                        <p style={{ float: "left" }}>
                          <b>export.txt</b>
                        </p>

                        <Button
                          floated="right"
                          color="green"
                          onClick={this._acceptPendingOrder}
                        >
                          <Icon name="check" />
                          {this.props.translation(
                            "orderadmin_exports_accept_export"
                          )}
                        </Button>
                        <Button
                          onClick={this._exportOrderFileAgain}
                          loading={this.state.isLoadingExportOrdersFileAgain}
                          floated="right"
                        >
                          <Icon name="save" />
                          {this.props.translation(
                            "orderadmin_exports_download_file"
                          )}
                        </Button>
                        <br />
                        <br />
                      </Segment>
                    </Message>
                    <br />
                  </Fragment>
                )}
            </Fragment>
          )}

          <Button
            floated="right"
            icon
            labelPosition="left"
            onClick={this._refreshOrderList}
            disabled={this.state.shouldDisableRefreshButton}
            loading={this.state.shouldDisableRefreshButton}
            positive
          >
            <Icon name="refresh" />
            {this.props.translation("orderadmin_refresh_order_list")}
          </Button>
          {!isShowingManuallyExportedOrders && !isShowingAlreadyExportedOrders && (
            <Button
              floated="right"
              icon
              labelPosition="left"
              onClick={this._exportOrdersClick}
              loading={this.state.isLoadingExportOrders}
              disabled={shouldDisableExportButton}
              color="red"
            >
              <Icon name="download" />
              {this.props.translation("orderadmin_export_all_orders")}
            </Button>
          )}

          <Grid columns={1} stackable>
            <Grid.Column>
              <Header as="h2">
                <Icon name="boxes" />
                {pageTitle}
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Button.Group>
                <Button
                  disabled={
                    !isShowingManuallyExportedOrders &&
                    !isShowingAlreadyExportedOrders
                  }
                  onClick={() => this._orderDisplayTypeButtonPressed(1)}
                  size="large"
                >
                  {this.props.translation("orderadmin_new_orders")}
                </Button>
                <Button
                  disabled={isShowingManuallyExportedOrders}
                  onClick={() => this._orderDisplayTypeButtonPressed(2)}
                  size="large"
                >
                  {this.props.translation("orderadmin_manually_exported")}
                </Button>
                <Button
                  disabled={isShowingAlreadyExportedOrders}
                  onClick={() => this._orderDisplayTypeButtonPressed(3)}
                  size="large"
                >
                  {this.props.translation("orderadmin_exported")}
                </Button>
              </Button.Group>
            </Grid.Column>
          </Grid>
          <Divider />
          <Grid columns={2} stackable>
            <Grid.Column width={12}>
              <Dropdown
                placeholder={this.props.translation("orderadmin_country")}
                fluid
                multiple
                selection
                value={selectedCountryDropdownOptions}
                onChange={this.handleCountriesSelectChange}
                options={this.state.countrySelectOptions}
                loading={this.state.countrySelectOptions.length < 1}
                disabled={this.state.countrySelectOptions.length < 1}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Button
                floated="right"
                color="grey"
                fluid
                onClick={this.useNewFiltrationButtonPressed}
              >
                {this.props.translation("orderadmin_use_filter")}
              </Button>
            </Grid.Column>
          </Grid>
          <Divider />
          <br />

          <Grid columns={2} stackable>
            <Grid.Column>
              {!isShowingManuallyExportedOrders &&
                !isShowingAlreadyExportedOrders && (
                  <p>
                    {this.props.translation(
                      "orderadmin_new_orders_description"
                    )}
                  </p>
                )}
              {isShowingManuallyExportedOrders &&
                !isShowingAlreadyExportedOrders && (
                  <p>
                    {this.props.translation(
                      "orderadmin_manually_exported_orders_description"
                    )}
                  </p>
                )}
              {isShowingAlreadyExportedOrders && (
                <p>
                  {this.props.translation(
                    "orderadmin_exported_orders_description"
                  )}
                </p>
              )}
            </Grid.Column>
            <Grid.Column>
              {!isShowingManuallyExportedOrders &&
                !isShowingAlreadyExportedOrders && (
                  <Checkbox
                    label={this.props.translation(
                      "orderadmin_also_display_orders_that_should_not_be_exported"
                    )}
                    checked={this.props.user.orderAdminDisplayNonExportOrders}
                    onChange={() =>
                      this.props.orderAdminSetDisplayNonExportOrders(
                        !this.props.user.orderAdminDisplayNonExportOrders
                      )
                    }
                  />
                )}
            </Grid.Column>
          </Grid>

          <Table singleLine compact selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  {this.props.translation(
                    "orderadmin_ordertable_orderid_column"
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.translation(
                    "orderadmin_ordertable_main_customer_column"
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.translation("orderadmin_ordertable_user_column")}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.translation(
                    "orderadmin_ordertable_order_date_column"
                  )}
                </Table.HeaderCell>
                <Table.HeaderCell>
                  {this.props.translation(
                    "orderadmin_ordertable_status_column"
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {orderItems.map((orderItem, index) => {
                let orderStatusValue = "";
                let rowIsPositive = true;
                let orderShouldBeListed = true;
                let rowIsPositiveOrNegative = true;

                if (orderItem.order_status === "new") {
                  orderStatusValue = this.props.translation(
                    "orderadmin_exports_not_exported"
                  );
                } else if (orderItem.order_status === "pending") {
                  orderStatusValue = this.props.translation(
                    "orderadmin_exports_awaiting_acceptance"
                  );
                } else if (orderItem.order_status === "exported_manually") {
                  orderStatusValue = this.props.translation(
                    "orderadmin_exports_manually_exported"
                  );
                } else if (orderItem.order_status === "await_export") {
                  orderStatusValue = this.props.translation("orderadmin_exports_hold_off_export");
                } else if (orderItem.order_status === "in_progress") {
                  orderStatusValue = "In Progress";
                  rowIsPositive = false;
                }

                if (
                  orderItem.order_status === "exported_manually" &&
                  isShowingManuallyExportedOrders
                ) {
                  orderShouldBeListed = true;
                  rowIsPositiveOrNegative = false;
                } else if (
                  orderItem.order_status === "exported_manually" &&
                  !isShowingManuallyExportedOrders
                ) {
                  orderShouldBeListed = false;
                } else if (
                  orderItem.order_status !== "exported_manually" &&
                  !isShowingManuallyExportedOrders
                ) {
                  orderShouldBeListed = true;
                } else if (
                  orderItem.order_status !== "exported_manually" &&
                  isShowingManuallyExportedOrders
                ) {
                  orderShouldBeListed = false;
                }

                if (
                  orderItem.order_status === "exported" &&
                  !isShowingAlreadyExportedOrders
                ) {
                  orderShouldBeListed = false;
                } else if (
                  orderItem.order_status !== "exported" &&
                  isShowingAlreadyExportedOrders
                ) {
                  orderShouldBeListed = false;
                }

                if (orderShouldBeListed) {
                  let orderCannotBeExported = false;
                  if (orderItem.export_to_pyramid === false) {
                    orderCannotBeExported = true;
                  }
                  if (orderCannotBeExported) {
                    orderStatusValue = "Exporteras inte till Pyramid";
                    rowIsPositive = false;
                  }

                  var orderCreatedByUser = "";
                  if (orderItem.created_by_user_first_name) {
                    if (orderItem.created_by_user_email) {
                      orderCreatedByUser =
                        orderItem.created_by_user_first_name +
                        " " +
                        orderItem.created_by_user_last_name +
                        " (" +
                        orderItem.created_by_user_email +
                        ")";
                    } else {
                      orderCreatedByUser =
                        orderItem.created_by_user_first_name +
                        " " +
                        orderItem.created_by_user_last_name;
                    }
                  }

                  let shouldDisplayRow = true;
                  if (
                    orderCannotBeExported &&
                    !this.props.user.orderAdminDisplayNonExportOrders
                  ) {
                    shouldDisplayRow = false;
                  }
                  if (isShowingAlreadyExportedOrders) {
                    rowIsPositive = false;
                    rowIsPositiveOrNegative = false;
                  }

                  return (
                    <Fragment key={index}>
                      {shouldDisplayRow && (
                        <Table.Row
                          negative={!rowIsPositive && rowIsPositiveOrNegative}
                          positive={rowIsPositive && rowIsPositiveOrNegative}
                          key={index}
                          onClick={() => this.handleRowClick(orderItem)}
                          style={{ cursor: "pointer" }}
                        >
                          <Table.Cell>#{orderItem.ID}</Table.Cell>
                          <Table.Cell>{orderItem.main_customer_id}</Table.Cell>
                          <Table.Cell>{orderCreatedByUser}</Table.Cell>
                          <Table.Cell>{orderItem.order_post_date}</Table.Cell>
                          <Table.Cell>{orderStatusValue}</Table.Cell>
                        </Table.Row>
                      )}
                    </Fragment>
                  );
                }
                return null;
              })}
            </Table.Body>
          </Table>
          <Dimmer
            active={this.state.showLoadingScreen}
            style={{ zIndex: "1" }}
            inverted
          >
            <Loader />
          </Dimmer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  translation: getTranslate(state.localize),
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      orderAdminExportOrders,
      orderAdminSetActiveCountries,
      orderAdminSetDisplayNonExportOrders,
      orderAdminOrdersSetActiveTab,
    },
    dispatch
  );
};

OrderAdminOrders = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderAdminOrders);
OrderAdminOrders = withRouter(OrderAdminOrders);

export default OrderAdminOrders;
