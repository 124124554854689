const getServerPath = window.location.protocol + '//' + window.location.host;
const themeLocation = '/wp-content/themes/wccd-react-theme';
const version = '0.6.7';
const localHostPath = "https://swepac.mycondesign.se";
// const printServiceBaseUrl = process.env.NODE_ENV ? "https://spareparts.swepac.com" : "https://sspp.wp.interactivated.me";
const printServiceBaseUrl = "https://spareparts.swepac.com";

const isLocalhost = () => {
	if (
		window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.1/8 is considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
	) {
		return true;
	}
	return false;
};

const returnPublic = {
	API_ENDPOINTS: {
		GET_ALL_POSTS: getServerPath + '/wp-json/wccd/v1/posts',
		GET_ALL_PAGES: getServerPath + '/wp-json/wccd/v1/pages',
		GET_ALL_MENUS: getServerPath + '/wp-json/wccd/v1/menus',
		GET_ALL_WIDGETS: getServerPath + '/wp-json/wccd/v1/widgets',

		GET_ALL_PRODUCTS: getServerPath + '/wp-json/wccd/v1/products/',
		GET_ALL_CATALOGIES: getServerPath + '/wp-json/wccd/v1/products/categories/',
		GET_ALL_MACHINE_TYPES: getServerPath + '/wp-json/wccd/v1/machine-categories',
		SEARCH_PRODUCTS: getServerPath + '/wp-json/wccd/v1/product-search',

		GET_COUNTRIES: getServerPath + '/wp-json/wccd-sp/v1/user/countrycodes',
		GET_CURRENCIES: getServerPath + '/wp-json/wccd/v1/currencies',
		GET_LANGUAGE_FRONTEND: getServerPath + '/wp-json/wccd/v1/translations',

		POST_USER: getServerPath + '/wp-json/wccd-sp/v1/auth',
		POST_GET_NEW_PASSWORD: getServerPath + '/wp-json/wccd-sp/v1/forgot-password',
		POST_USER_REFRESH: getServerPath + '/wp-json/wccd-sp/v1/user/refresh',
		PUT_UPDATE_USER: getServerPath + '/wp-json/wccd-sp/v1/user/update',
		POST_NEW_USER: getServerPath + "/wp-json/wccd-sp/v1/register",

		POST_ORDER: getServerPath + '/wp-json/wccd-sp/v1/order',

		ORDER_ADMIN_GET_ORDERS: getServerPath + '/wp-json/wccd-sp/v1/order-admin/orders',
		ORDER_ADMIN_GET_SINGLE_ORDER: getServerPath + '/wp-json/wccd-sp/v1/order-admin/order',
		ORDER_ADMIN_UPDATE_ORDER: getServerPath + '/wp-json/wccd-sp/v1/order-admin/orders/update/',
		ORDER_ADMIN_GET_USERS: getServerPath + '/wp-json/wccd-sp/v1/order-admin/users',
		ORDER_ADMIN_GET_SINGLE_USER: getServerPath + '/wp-json/wccd-sp/v1/order-admin/user',
		ORDER_ADMIN_EXPORT_ORDERS: getServerPath + '/wp-json/wccd-sp/v1/order-admin/export-orders',
		PUT_ORDER_ADMIN_UPDATE_USER: getServerPath + '/wp-json/wccd-sp/v1/order-admin/update-user',
		CE_ADMIN_GET_PRINT_LIST: getServerPath + '/wp-json/wccd-sp/v1/order-admin/ce-print-documents',
		CE_ADMIN_ACCEPT_CE_PRINT: getServerPath + '/wp-json/wccd-sp/v1/order-admin/accept-ce-document-prints',
		ORDER_ADMIN_COUNTRY_OPTIONS: getServerPath + '/wp-json/wccd-sp/v1/order-admin/countryoptions',

		SEARCH_CE_OCH_MOTORINTYG: getServerPath + '/wp-json/wccd/v1/ce-och-motorintyg-sok',
		SEARCH_MAIN_PRODUCT_BY_SERIAL_NUMBER: getServerPath +  '/wp-json/wccd/v1/search-main-product-by-ce-serial-number',
		GET_HOMEPAGE_CONTENT: getServerPath +  '/wp-json/wccd/v1/get-homepage-content',
		GET_CATEGORY_SERVICE_INFO: getServerPath +  '/wp-json/wccd/v1/get-category-service-info?category_slug=',
		GET_MAIN_PRODUCT_SERVICE_INFO_MENU: getServerPath +  '/wp-json/wccd/v1/get-service-info-menu?mainProductSlug=',
		GET_IMAGE_MAP_BASE_URL: getServerPath + '/wp-json/wccd/v1/get-image-map/'
	},
	xhrConfig: {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	},
	themeLocation: themeLocation,
	version: version,
	serverPath: getServerPath,
	printServiceBaseUrl,
};

const returnLocalhost = {
	API_ENDPOINTS: {
		GET_ALL_POSTS: localHostPath + '/wp-json/wccd/v1/posts',
		GET_ALL_PAGES: localHostPath + '/wp-json/wccd/v1/pages',
		GET_ALL_MENUS: localHostPath + '/wp-json/wccd/v1/menus',
		GET_ALL_WIDGETS: localHostPath + '/wp-json/wccd/v1/widgets',

		/**
		 * Hämta från test-server
		 */
		GET_ALL_PRODUCTS: localHostPath + '/wp-json/wccd/v1/products/',
		GET_ALL_CATALOGIES: localHostPath + '/wp-json/wccd/v1/products/categories/',
		GET_ALL_MACHINE_TYPES: localHostPath + '/wp-json/wccd/v1/machine-categories',
		GET_COUNTRIES: localHostPath + '/wp-json/wccd-sp/v1/user/countrycodes',
		GET_CURRENCIES: localHostPath + '/wp-json/wccd/v1/currencies',
		GET_LANGUAGE_FRONTEND: localHostPath + '/wp-json/wccd/v1/translations',
		SEARCH_PRODUCTS: localHostPath + '/wp-json/wccd/v1/product-search',
		// ---SLUT

		/**
		 * Hämta från lokal json-fil
		 */
		// GET_ALL_PRODUCTS: '/json/products.json',
		// GET_ALL_CATALOGIES: '/json/categories.json',
		// GET_ALL_MACHINE_TYPES: '/json/machine-categories.json',
		// GET_COUNTRIES: '/json/countrycodes.json',
		// GET_LANGUAGE_FRONTEND: '/json/translations.json',
		// ---SLUT

		POST_USER: localHostPath + '/wp-json/wccd-sp/v1/auth',
		POST_GET_NEW_PASSWORD: localHostPath + '/wp-json/wccd-sp/v1/forgot-password',
		POST_USER_REFRESH: localHostPath + '/wp-json/wccd-sp/v1/user/refresh',
		PUT_UPDATE_USER: localHostPath + '/wp-json/wccd-sp/v1/user/update',
		POST_NEW_USER: localHostPath + '/wp-json/wccd-sp/v1/register',

		POST_ORDER: localHostPath + '/wp-json/wccd-sp/v1/order',

		ORDER_ADMIN_GET_ORDERS: localHostPath + '/wp-json/wccd-sp/v1/order-admin/orders',
		ORDER_ADMIN_GET_SINGLE_ORDER: localHostPath + '/wp-json/wccd-sp/v1/order-admin/order',
		ORDER_ADMIN_UPDATE_ORDER: localHostPath + '/wp-json/wccd-sp/v1/order-admin/orders/update/',
		ORDER_ADMIN_GET_USERS: localHostPath + '/wp-json/wccd-sp/v1/order-admin/users',
		ORDER_ADMIN_GET_SINGLE_USER: localHostPath + '/wp-json/wccd-sp/v1/order-admin/user',
		ORDER_ADMIN_EXPORT_ORDERS: localHostPath + '/wp-json/wccd-sp/v1/order-admin/export-orders',
		PUT_ORDER_ADMIN_UPDATE_USER: localHostPath + '/wp-json/wccd-sp/v1/order-admin/update-user',
		CE_ADMIN_GET_PRINT_LIST: localHostPath + '/wp-json/wccd-sp/v1/order-admin/ce-print-documents',
		CE_ADMIN_ACCEPT_CE_PRINT: localHostPath + '/wp-json/wccd-sp/v1/order-admin/accept-ce-document-prints',
		ORDER_ADMIN_COUNTRY_OPTIONS: localHostPath + '/wp-json/wccd-sp/v1/order-admin/countryoptions',

		SEARCH_CE_OCH_MOTORINTYG: localHostPath + '/wp-json/wccd/v1/ce-och-motorintyg-sok',
		SEARCH_MAIN_PRODUCT_BY_SERIAL_NUMBER: localHostPath +  '/wp-json/wccd/v1/search-main-product-by-ce-serial-number',
		GET_HOMEPAGE_CONTENT: localHostPath +  '/wp-json/wccd/v1/get-homepage-content',
		GET_CATEGORY_SERVICE_INFO: localHostPath +  '/wp-json/wccd/v1/get-category-service-info?category_slug=',
		GET_MAIN_PRODUCT_SERVICE_INFO_MENU: localHostPath +  '/wp-json/wccd/v1/get-service-info-menu?mainProductSlug=',
		GET_IMAGE_MAP_BASE_URL: localHostPath + '/wp-json/wccd/v1/get-image-map/'
	},
	xhrConfig: {
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	},
	themeLocation: '',
	version: version,
	serverPath: localHostPath,
	printServiceBaseUrl,
};

export default (isLocalhost() ? returnLocalhost : returnPublic);
